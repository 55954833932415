import React from 'react';
import cx from 'classnames';

import './Footer.sass';

const config = window.config;
const www = config && config.www && config.www.address;

const links = [
  {title: 'Privacy', url: `${www}/privacy-policy`},
  {title: 'Terms', url: `${www}/terms-of-service`},
  {title: 'Help', url: `https://help.imperative.com/support/home`},
  {title: 'About', url: `${www}/about`},
]

const Footer = ({position, maxWidth}) => {
  if(!www) {
    console.error('No website address in config');
  }

  return (
    <footer className={cx("Footer", position)}>
      <div
        className="container"
        style={{maxWidth: `${maxWidth}px`, padding: maxWidth ? 0 : null}}
      >
        <div className="Footer__links">
          {links.map((l, i) => (
            <a
              key={i}
              href={l.url}
              title={l.title}
              target="_blank"
            >
              {l.title}
            </a>
          ))}
          <div className="Footer__copyright">
            &copy; Copyright {new Date().getFullYear()}, Imperative. All rights reserved.
          </div>
          <div className="Footer__contact">
            Contact us: <a href="mailto:imperativesupport@chronus.com">imperativesupport@chronus.com</a>
          </div>
          <button
            className='Footer__freshdesk'
            aria-label="Need Help? Open support form"
            onClick={() => window.FreshworksWidget && window.FreshworksWidget('open')}
          >Need help?</button>
        </div>
      </div>
    </footer>
  )
}

export default Footer;
